export const GET_APPOINTMENT_EVENTS_LIST_LOADING = "GET_APPOINTMENT_EVENTS_LIST_LOADING";
export const SET_APPOINTMENT_EVENTS_LIST = "SET_APPOINTMENT_EVENTS_LIST";
export const ADD_APPOINTMENT_EVENT_LOADING = "ADD_APPOINTMENT_EVENT_LOADING";
export const GET_APPOINTMENT_EVENT_DETAILS_LOADING = "GET_APPOINTMENT_EVENT_DETAILS_LOADING";
export const SET_APPOINTMENT_EVENT_DETAILS = "SET_APPOINTMENT_EVENT_DETAILS"
export const GET_APPOINTMENT_EVENT_DETAILS = "GET_APPOINTMENT_EVENT_DETAILS";
export const DELETE_APPOINTMENT_EVENT_LOADING = "DELETE_APPOINTMENT_EVENT_LOADING";
export const SET_APPOINTMENTS_LIST = "SET_APPOINTMENTS_LIST";
export const GET_MENTOR_AVAILABILITIES_LIST_LOADING = "GET_MENTOR_AVAILABILITIES_LIST_LOADING";
export const SET_MENTOR_AVAILABILITIES_LIST = "SET_MENTOR_AVAILABILITIES_LIST";
export const GET_APPOINTMENT_MANAGE_EVENT_LOADING = "GET_APPOINTMENT_MANAGE_EVENT_LOADING";
export const GET_MENTOR_APPOINTMENT_DELETE_LOADING = "GET_MENTOR_APPOINTMENT_DELETE_LOADING";
export const GET_SELECTED_SPEAKER_LOADING = "GET_SELECTED_SPEAKER_LOADING";
export const GET_EDIT_MENTOR_AVAILABILITY_LOADING = "GET_EDIT_MENTOR_AVAILABILITY_LOADING";
export const GET_EDIT_APPOINTMENT_EVENT_FORM_LOADING = "GET_EDIT_APPOINTMENT_EVENT_FORM_LOADING";
export const GET_APPOINTMENT_LIST_LOADING = "GET_APPOINTMENT_LIST_LOADING";
export const RESET_APPOINTMENTS_LIST = "RESET_APPOINTMENTS_LIST";
