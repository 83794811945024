import { getQuizTestHandlers } from "./getQuizTestDetails";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentQuizTest: [],
  quizQuestions: [],
  error: "4444",
  quizTestsList: [],
  quizTestsQuestions: [],
  quizQuestionDetails: {},
  quizTestTakens: [],
  candidateReportReviewDetails: [],
  quizTestsListLoading: false,
  quizTestDataLoading: false,
  quizQuestionDetailsLoading: false,
  quizTestTakenLoading: false,
  candiateReportReviewDetailsLoading: false,
  deleteLoading: false,
  quizTestSubmittingLoading: false,
  questionCreateLoading: false,
};

const handlers = {
  ...getQuizTestHandlers,
};

const quizTestReducer = createReducer(initialState, handlers);

export default quizTestReducer;
