import * as constants from "../constants";

const setAppointmentEventsList = (state, action) => {
  return {
    ...state,
    appointmentEvents: action.payload.appointmentEvents,
    appointmentEventsCount: action.payload.count,
  }
};

const setAppointmentsList = (state, action) => {
  const appointmentArrayRef = state.appointmentsList 
  appointmentArrayRef.push(...action.payload.appointments)
  return {
    ...state,
    appointmentsList: appointmentArrayRef,
    appointmentsCount: action.payload.count,
  }
};

const resetAppointmentsList = (state, action) => {
  return {
    ...state,
    appointmentsList: action.payload,
  }
};

const setAppointmentEventDetails = (state, action) => {
  return {
    ...state,
    appointmentEventDetails: action.payload,
  }
};

const setMentorAvailabilitiesList = (state, action) => {
  return {
    ...state,
    mentorAvailabilitiesList: action.payload,
  }
};


export const dataHandlers = {
  [constants.SET_APPOINTMENT_EVENTS_LIST]: setAppointmentEventsList,
  [constants.SET_APPOINTMENTS_LIST]: setAppointmentsList,
  [constants.SET_APPOINTMENT_EVENT_DETAILS]: setAppointmentEventDetails,
  [constants.SET_MENTOR_AVAILABILITIES_LIST]: setMentorAvailabilitiesList,
  [constants.RESET_APPOINTMENTS_LIST]: resetAppointmentsList,
};