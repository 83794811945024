export const GET_QUIZ_TEST_DETAILS = "GET_QUIZ_TEST_DETAILS";
export const GET_QUIZ_QUESTIONS = "GET_QUIZ_QUESTIONS";
export const GET_QUIZ_TESTS_LIST = "GET_QUIZ_TESTS_LIST";
export const GET_TEST_QUESTIONS = "GET_TEST_QUESTIONS";
export const GET_QUIZ_QUESTIONS_DETAILS = "GET_QUIZ_QUESTIONS_DETAILS";
export const GET_QUIZ_OVERVIEW = "GET_QUIZ_OVERVIEW";
export const GET_QUIZ_TEST_TAKEN = "GET_QUIZ_TEST_TAKEN";
export const GET_CANDIDATE_DETAILS = "GET_CANDIDATE_DETAILS";

//constants for loader
export const CREATE_TEST_LOADING = "CREATE_TEST_LOADING";
export const GET_QUIZ_TEST_LIST_LOADING = "GET_QUIZ_TEST_DETAILS_LOADING";
export const GET_TEST_DATA_LOADING = "GET_TEST_DATA_LOADING";
export const DELETE_LOADING = "DELETE_LOADING";
export const GET_TEST_QUESTION_DETAILS_LOADING =
  "GET_TEST_QUESTION_DETAILS_LOADING";
export const GET_TEST_TAKEN_LOADING = "GET_TEST_TAKEN_LOADING";
export const CREATE_QUESTION_LOADING = "CREATE_QUESTION_LOADING";
export const GET_CANDIDATE_DETAILS_LOADING = "GET_CANDIDATE_DETAILS_LOADING";
export const GET_QUIZ_QUESTION_DETAILS_LOADING =
  "GET_QUIZ_QUESTION_DETAILS_LOADING";
export const SUBMITTING_QUIZ_TEST_LOADING = "SUBMITTING_QUIZ_TEST_LOADING";

//

export const GET_TEST_USER = "GET_TEST_USER";
